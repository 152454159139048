<template>
  <div class="assets s-page">
    <div class="s-header">
      <van-nav-bar :title="$t('assets.title')"> </van-nav-bar>
    </div>
    <div class="maincontent skiptranslate s-content">
      <!-- 背景区域 -->
      <div class="total">
        <div class="head">
          <van-row class="title">
            <span style="color: white; font-size: 15px; display: flex;"
              >{{ $t('assets.zassets') }}
              <span
                style="margin-left: 5px;"
                @click="eyeevent"
              >
                <van-icon v-if="eye" name="eye" size="18" />
                <van-icon v-else name="closed-eye" size="18" />
              </span>
            </span>
          </van-row>
          <van-row class="uid">
            <span> UID:{{ userinfo.username }} </span>
          </van-row>
          <van-row class="money">
            <div class="balance">
              <div class="money">
                <div v-if="eye">
                  <h2><span>{{ balance.usd }}</span> (USD)</h2>
                </div>
                <div v-else>
                  <h2>******</h2>
                </div>
              </div>
            </div>
          </van-row>
          <van-row class="headMenu">
            <van-col span="6" @click="recharge">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/assets-cz.png')"
              />
              <div>{{ $t('assets.charge') }}</div>
            </van-col>
            <van-col span="6" @click="withdraw">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/assets-tb.png')"
              />
              <div>{{ $t('assets.withdraw') }}</div>
            </van-col>
            <van-col span="6" @click="transfer">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/assets-hz.png')"
              />
              <div>{{ $t('assets.transfer') }}</div>
            </van-col>
            <van-col span="6" @click="$router.push('/trade/shopping/trading')">
              <van-image
                width="2rem"
                height="2rem"
                :src="require('../../assets/img/assets-dh.png')"
              />
              <div>{{ $t('assets.duihuan') }}</div>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- 今日统计区域 -->
      <van-row class="today">
        <van-row class="today-left">
          <h3>{{ $t('assets.tprofit') }}</h3>
          <van-row class="today-left-span">
            <span>{{ tongji['profit'] }}</span>USDT
          </van-row>
        </van-row>
        <van-row class="today-right">
          <van-row
            style="background-color: rgb(248, 248, 248); margin-bottom: 3%; height: 47%; border-radius: 0.234375rem; padding: 0.46875rem;"
          >
            <h5 style="padding: 0">{{ $t('assets.tamount') }}</h5>
            <van-row class="today-right-span">
              <span>{{ tongji['price'] }}</span>
            </van-row>
          </van-row>
          <van-row
            style="background-color: rgb(248, 248, 248); height: 47%; border-radius: 7.5px; padding: 0.46875rem;"
          >
            <h5 style="padding: 0">{{ $t('assets.torders') }}</h5>
            <van-row class="today-right-span">
              <span>{{ tongji['count'] }}</span>
            </van-row>
          </van-row>
        </van-row>
      </van-row>
      <!-- 信用分 -->
      <div>
        <div class="main-head">
          <h3>{{ $t('assets.credit') }}</h3>
        </div>
        <canvas id="main"></canvas>
        <div class="main-bottom">
          <span> {{ $t('assets.high') }}100 </span>
        </div>
      </div>
      <div
        style="background-color: rgb(248, 248, 248); height: 10px; width: 100%;"
      ></div>
      <!-- 币币 -->
      <van-tabs color="#004EA3" class="currency">
        <van-tab :title="$t('assets.bibi' )">
          <van-row>
            <van-row class="currency-Increase" @click="$router.push('/trade/shopping/trading')">
              <van-col span="8">
                <span style="font-size: 17px;">
                  USDT <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">
                    {{ $t('assets.usable') }}
                  </span>
                  <br />
                  <span>{{ balance.usable }}</span>
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{
                    $t('assets.freeze')
                  }}</span>
                  <br />
                  {{ balance.freeze }}
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{ $t('assets.convert') }}</span>
                  <br />
                  {{ balance.usable }}
                </span></van-col
              >
            </van-row>
          </van-row>
        </van-tab>
        <van-tab :title="$t('assets.fiat')">
          <van-row>
            <van-row class="currency-Increase"  @click="$router.push('/trade/shopping/trading')">
              <van-col span="8">
                <span style="font-size: 17px;">
                  USDT <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">
                    {{ $t('assets.usable') }}
                  </span>
                  <br />
                  <span>{{ balance.legal_tender }}</span>
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{
                    $t('assets.freeze')
                  }}</span>
                  <br />
                  {{ balance.legal_tender_freeze }}
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{
                    $t('assets.convert')
                  }}</span>
                  <br />
                  {{ balance.legal_tender }}
                </span></van-col
              >
            </van-row>
          </van-row>
        </van-tab>
        <van-tab :title="$t('assets.innovate')">
          <van-row>
            <van-row class="currency-Increase" @click="$router.push('/trade/shopping/trading')">
              <van-col span="8">
                <span style="font-size: 17px;">
                  USDT <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">
                    {{ $t('assets.usable') }}
                  </span>
                  <br />
                  <span>{{ balance.contract }}</span>
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{
                    $t('assets.freeze')
                  }}</span>
                  <br />
                  {{ balance.contract_freeze }}
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{
                    $t('assets.convert')
                  }}</span>
                  <br />
                  {{ balance.contract }}
                </span></van-col
              >
            </van-row>
          </van-row>
        </van-tab>
        <van-tab title="DeFi">
          <van-row>
            <van-row class="currency-Increase" @click="$router.push('/trade/shopping/trading')">
              <van-col span="8">
                <span style="font-size: 17px;">
                  USDT <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">
                    {{ $t('assets.usable') }}
                  </span>
                  <br />
                  <span>{{ balance.defi }}</span>
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{
                    $t('assets.freeze')
                  }}</span>
                  <br />
                  {{ balance.defi_freeze }}
                </span>
              </van-col>
              <van-col span="8">
                <span style="font-size:17px">
                  <span style="color: rgba(0, 0, 0, 0);">
                    213
                  </span>
                  <br />
                  <span style="font-size: 15px; color: rgb(33, 33, 33);">{{
                    $t('assets.convert')
                  }}</span>
                  <br />
                  {{ balance.defi }}
                </span></van-col
              >
            </van-row>
          </van-row>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      eye: true,
      // canvas
      context: {},
      score: 0,
      userinfo: {},
      balance: {},
      tongji: []
    }
  },
  created() {
    this.getinfo()
  },
  mounted() {},
  methods: {
    // 信用分画图
    createcanvas() {
      this.$nextTick(() => {
        const canvas = document.querySelector('#main')
        this.context = canvas.getContext('2d')
        this.context.rotate(Math.PI / 2)
        // 刻度
        for (let i = 0; i <= 20; i++) {
          this.context.save()
          this.context.lineWidth = 3
          if (i <= this.score / 5) {
            this.context.strokeStyle = '#004EA3'
          } else {
            this.context.strokeStyle = '#ccc'
          }
          const reg = i * (180 / 20)
          const pai = Math.PI / 180
          this.context.translate(130, -150)
          this.context.rotate(reg * pai)
          this.context.beginPath()
          this.context.moveTo(0, 120)
          this.context.lineTo(0, 100)
          this.context.stroke()
          this.context.restore()
        }
        // 信用分
        this.context.rotate(-Math.PI / 2)
        this.context.font = '700 46px Arial'
        this.context.fillStyle = '#004EA3'
        if (this.score === 100) {
          this.context.fillText(100, 110, 140)
        } else {
          this.context.fillText(this.score, 130, 140)
        }
        this.context.closePath()
      })
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/user/index')
      if (data) {
        if (data.code === 200) {
          this.score = +data.data.userinfo.credit_score
          this.createcanvas()
          this.userinfo = data.data.userinfo
          this.balance = data.data.balance
          this.tongji = data.data.tongji
        }
      }
    },
    eyeevent() {
      this.eye = !this.eye
    },
    // 充值
    recharge() {
      this.$router.push('/assets/recharge')
    },
    // 提币
    withdraw() {
      this.$router.push('/assets/withdraw')
    },
    // 划转
    transfer() {
      this.$router.push('/assets/transfer')
    },
    // 兑换
    conversion() {
      this.$router.push('/assets/conversion')
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  // 背景区域
  .total {
    width: 100%;
    justify-content: center;
    margin-bottom: 0.53333rem;
    background: url("../../assets/img/assets-head-bg.png") no-repeat;
    //background-color: #0055ff;
    background-size: 100% 100%;
    .head {
      padding: 0.75rem;
      color: #fff;
      border-radius: 0.53333rem;
      text-align: left;
      .title {
        margin-bottom: 0.625rem;
      }
      .uid {
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 0.875rem;
        color: #FFFFFF;
      }
      .headMenu {
        text-align: center;
        font-weight: 500;
        font-size: 0.875rem;
        color: #FFFFFF;
      }
      .balance{
        padding: 0 3.125rem;
        text-align: center;
        .money{
          background: url("../../assets/img/assets-head-border.png") no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          height: 3.125rem;
          line-height: 3.125rem;
          margin-bottom: 0.9375rem;
          span{
            font-size: 1.5rem;
          }
        }
        /*h1{
          width: 100%;
          height: 3.125rem;
          line-height: 2.5rem;
        }*/
      }
    }
  }
  // 今日统计
  .today {
    width: 95%;
    height: 9.86667rem;
    margin-left: 2.5%;
    display: flex;
    color: #212121;
    .today-left {
      width: 50%;
      height: 100%;
      background-color: #f8f8f8;
      border-radius: 0.4rem;
      h3 {
        height: 20%;
        text-align: left;
        padding: 0.4rem;
        font-size: 0.90667rem;
      }
      .today-left-span {
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        span {
          color: #85B480;
          font-weight: 700;
          font-size: 1.875rem;
          margin-right: 0.21333rem;
        }
      }
    }
    .today-right {
      width: 48%;
      margin-left: 2%;
      height: 100%;
      border-radius: 0.4rem;
      h5 {
        text-align: left;
        padding: 0.4rem;
        font-size: 0.90667rem;
      }
      .today-right-span {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: bold;
          font-size: 1.5rem;
          margin-right: 0.21333rem;
          color: #85B480;
          margin-top: 0.26667rem;
        }
      }
    }
  }
  // 信用分
  .main-head {
    background-color: #fff;
    padding-top: 0.53333rem;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 0.53333rem;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    color: #000;
    h3 {
      text-align: left;
      padding-left: 0.4rem;
      font-size: 0.90667rem;
    }
  }
  .main {
    height: 9rem;
    background-color: #fff;
    width: 95%;
    margin-left: 2.5%;
    height: 9rem;
  }
  .main-bottom {
    padding: 0 0.84375rem;
    background-color: #fff;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    margin: 2.03125rem 0 0.9375rem;
    text-align: right;
    span {
      font-weight: bold;
      font-size: 0.75rem;
      color: #004EA3;
    }
  }
  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }
  /deep/.van-tab {
    font-size: 1rem;
    color: #999999;
    font-weight: 500;
  }
  /deep/.van-tab--active {
    color: #004EA3;
  }
  //比比资产
  .currency {
    ::v-deep {
      .van-tabs__wrap {
        border-bottom: 1px solid #BABABA;
      }
    }
    .currency-Increase {
      color: #212121;
      padding: 0.9375rem 0.78125rem ;
      font-size: 0.93333rem;
      display: flex;
      align-items: center;
      line-height: 1.73333rem;
      border-bottom: 0.02667rem solid #fff;
      margin-bottom: 0.26667rem;
      text-align: left;
    }
  }
}
</style>
